import React, { useState, useEffect } from 'react';
import Form from '../Components/form.js';
import { Button } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import './Auth.css'

const Auth = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedForm, setSelectedForm] = useState(undefined);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
            console.log(res);
          googleLogging(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  const closeForm = () => {
    setShowForm(false);
  };

  const login = (data) => {
    // TODO: write codes to login
    console.log(data);
    localStorage.setItem('user', JSON.stringify(data));

    fetch(props.server_url + '/api/auth/login', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(data)
    }).then((res) => {
      res.json().then((data) => {
        if (data.msg === 'Logged in') {
          fetch(props.server_url + '/').then((response) => console.log(response));
          props.changeScreen('lobby');
        } else {
          alert(data.msg);
        }
      });
    });
  };

  const googleLogging = (input) => {
    // TODO: write codes to login
    // console.log(data);
    const data=JSON.stringify({ username: input.given_name, password: "Test",name:input.name });
    localStorage.setItem('user', data);

    fetch(props.server_url + '/api/auth/loginGoogle', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: data
    }).then((res) => {
      res.json().then((data) => {
        if (data.msg === 'Logged in') {
          fetch(props.server_url + '/').then((response) => console.log(response));
          props.changeScreen('lobby');
        } else {
          alert(data.msg);
        }
      });
    });
  };

  const register = (data) => {
    localStorage.setItem('user', JSON.stringify(data));

    fetch(props.server_url + '/api/auth/register', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(data)
    }).then((res) => {
      res.json().then((data) => {
        if (data.status) {
          alert(data.msg);
          // Redirect or handle successful registration as needed
          // After successful login or registration
          props.changeScreen('lobby');
        } else {
          alert(data.msg);
        }
      });
    });
  };

  const responseMessage = (response) => {
    console.log('Great Success');
    console.log(response);
    const token = response.credentials;
    const decodedToken = jwtDecode(token);
    console.log(decodedToken);
    const name = decodedToken.name;
    console.log(name);
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  let display = null;
  if (showForm) {
    let fields = [];
    if (selectedForm === 'login') {
      fields = ['username', 'password', 'question'];
      display = (
        <div style={{position: 'absolute',top: '15rem',width: '100vw'}}>
          <Form fields={fields} close={closeForm} type="login" submit={login} key={selectedForm} />
          <Button style={{marginTop: '2rem'}} variant='contained' onClick={googleLogin}>Sign in with Google 🚀</Button>
        </div>
      );
    } else if (selectedForm === 'register') {
      fields = ['username', 'password', 'name', 'question'];
      display = (
        <div style={{position: 'absolute',top: '15rem',width: '100vw'}}>
          <Form fields={fields} close={closeForm} type="register" submit={register} key={selectedForm}/>

        </div>
      );
    }
  } else {
    display = (
      <div style={{height: "9rem", width:"100vw", margin:'0 auto'}}>
        <button className='btn' onClick={() => setShowForm(true, setSelectedForm('login'))}> Login </button>
        <button className='btn' onClick={() => setShowForm(true, setSelectedForm('register'))}> Register </button>
      </div>
    );
  }

  const container = {
    textAlign: 'center',
  };

  return (
    <div style={container} className='home'>
      <h1 style={{textAlign:"left", color: "#ffffff", fontSize:"4rem", width:'60vw', margin:" 4rem 0 0 6rem"}}>Create Room</h1>
      <h1 style={{textAlign:"left", color: "#ffffff", fontSize:"6rem", width:'60vw', margin:" 0 0 0 6rem"}}>Share Tool</h1>
      <h1 style={{textAlign:"left", color: "#ffffff", fontSize:"8rem", width:'40vw', margin:" 0 0 0 6rem"}}>Stay Cool</h1>
      {display}
    </div>
  );
};

export default Auth;
