import react from "react";
import Auth from './Screens/Auth.js';
import Lobby from "./Screens/Lobby.js";
import Chatroom from "./Screens/Chatroom.js";
import io from 'socket.io-client';


const server_url = "https://cs110-lab6-test7.onrender.com";


class ScreenHandler extends react.Component{
    constructor(props){
        super(props);
        this.socket = io(server_url,{
      cors:{
        origin:server_url,
        credentials:true,
      },
      transports:['websocket']
      
    });
        this.state = {
            screen: undefined,
        }
    }
    sendChat = (text) => {
        console.log("in front", text)
        this.socket.emit("chat message", text);
      }
    componentDidMount(){
        // checking if the user has active session
        // if yes, then show lobby. if no, then show auth
        fetch(server_url, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((res) => {
            res.json().then((data) => {
                if (data.message == "logged in"){
                    this.setState({screen: "lobby"});
                }
                else{
                    this.setState({screen: "auth"});
                }
            });
        });
    }

    // changeScreen = (screen) => {
    //     this.setState({screen: screen});
    // }
    changeScreen = (screen) => {
        this.setState({ screen: screen }, () => {
            if(this.state.screen == "lobby"){
          if (!this.pageRefreshed) {
            this.pageRefreshed = true;
            window.location.reload();
          }
        }
        });
      };

    render(){
        let display = "loading...";
        if (this.state.screen == "auth"){
            display = <Auth server_url = {server_url} changeScreen={this.changeScreen}/>;
        }
        else if (this.state.screen == "lobby"){
            display = <Lobby server_url = {server_url} changeScreen={this.changeScreen} socket={this.socket}/>;
        }
        else if (this.state.screen == "chatroom"){
            
            display = <Chatroom server_url = {server_url} changeScreen={this.changeScreen} sendChat={this.sendChat} socket={this.socket}/>;
        }
        return(
            <div>
                {display}
            </div>
        );
    }
}

export default ScreenHandler;
