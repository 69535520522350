import react from "react";
import { Button, TextField } from "@mui/material";

class Form extends react.Component{
    constructor(props){
        super(props);
        // for each item in props.fields, create an item in this.state.fields
        let fields = [];
        for (let i = 0; i < props.fields.length; i++) {
            fields.push(["", props.fields[i]]);
        }
        this.state = {
            fields: fields,
        }
    }

    handleChange = (event, index) => {
        let fields = this.state.fields;
        fields[index][0] = event.target.value;
        this.setState({fields: fields});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let fields = this.state.fields;
        let data = {};
        for (let i = 0; i < fields.length; i++) {
            data[fields[i][1]] = fields[i][0];
        }
        this.props.submit(data);
    }

    render(){

        const container = {
            minHeight: '36rem',
            maxHeight: '50rem',
            maxWidth: '400px',
            margin: '0 auto',
            padding: '20px',
            background: '#ffffff',
            borderRadius: "30px",
            display: 'flex',
            flexDirection: 'column'
        };
        const closeButton = {
            display: 'flex',
            justifyContent:'end'
        };
        const submitButton = {
            padding: '10px 20px',
            margin: '10px',
            border: 'none',
            borderRadius: '4px',
        }

        return (
            <div style={container}>
                <div style={closeButton}>
                    <Button onClick={this.props.close}> x </Button>
                </div>
                <h3 style={{fontSize:'2rem', margin: '0'}}> {this.props.type} </h3>
                <h2>Security Question: <br></br>What city were you born in?</h2>
                <form onSubmit={this.handleSubmit}>
                    {this.state.fields.map((field, index) => {
                        return(
                            <div>
                                <TextField 
                                    variant="outlined" 
                                    margin="normal"
                                    key={"auth"+field[1]} 
                                    label={field[1]} 
                                    type={field[1] === "password" ? "password" : "text"}
                                    onChange={(event) => this.handleChange(event, index)}
                                />
                            </div>
                        );
                    })}
                    {/* <input type="submit"></input> */}
                    <Button style={submitButton} variant="contained" type="submit">Submit</Button>
                </form>

            </div>
        );
    }
}

export default Form;