import React, { useState, useEffect } from "react";
import { Avatar, Button, ButtonGroup, TextField } from "@mui/material";
import Picker from '@emoji-mart/react'
import 'font-awesome/css/font-awesome.min.css';
import './Chatroom.css'

class Chatroom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      messages: [],
      searchText: '',
      isReactionDisplayed: false,
      currIndex: -1,
      isEmojiChanged: false
    };

    this.props.socket.on("connect", () => {
      console.log("Connected to the server(clientside)");
    });

    this.props.socket.on('chat message', (message) => {
      console.log("got message back from websocket:",message)
      this.setState((prevState) => ({
        messages: [...prevState.messages, message],
      }));
    });
    this.props.socket.on('room history', (roomHistory) => {
      console.log("got room history back from websocket:", roomHistory);
      this.setState({ messages: roomHistory });
    });

    this.props.socket.on('chat reaction', (message) => {
      console.log("got chat reaction back from websocket:", message);
      updateMessages(message)
    })

    this.props.socket.on('reaction countup', (message) => {
      console.log("got reaction countup back from websocket:", message);
      updateMessages(message);
    })

    const updateMessages = (message) => {
      const newArray = this.state.messages;
      for (let i=0; i < newArray.length; i++){
        if (newArray[i]._id === message._id) {
          newArray.splice(i, 1, message);
          break;
        }
      }
      this.setState(({
        messages: newArray
      }))
    }
    
    const room=JSON.parse(localStorage.getItem("room"));
    console.log("room is:",room)
    this.props.socket.emit("join room", room ); // . move this to chatroom
    console.log("emitted join room");
    
  }

  handleTextChange = (e) => {
    this.setState({ text: e.target.value });
  }

  sendChat2 = (message) => {
    console.log("in front first", message);
    this.props.socket.emit("chat message", message);
    const room = JSON.parse(localStorage.getItem("room"));
  }

  handleBack = () => {
    this.props.changeScreen("lobby");
  }

  handleSendChat = () => {
    const username = JSON.parse(localStorage.getItem("user")).username;
    const room = JSON.parse(localStorage.getItem("room"));

    const { text } = this.state;
    const message = {
      message: text,
      sender: username,
      room: room,
    };
    this.sendChat2(message); // Pass the message object
    this.setState({ text: '' });

    // fetch(this.props.server_url + '/api/rooms/updateEmoji/' + message.id)
    //   .then(response => console.log(response.json()))
    //   .then(data => this.setState({ messages: data }));
  }

  logout = () => {

    const username = JSON.parse(localStorage.getItem("user")).username;

    fetch(this.props.server_url+ "/api/auth/logout", {
        method: "POST",
        mode:"cors",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify({username}),
    }).then((res) => {
        res.json().then((data) => {
            if (data.msg === "Logged out"){
                this.props.changeScreen("auth");

            }
            else{
                alert(data.msg)

            }
        });
    });
  };
  onReactionClick = (index) => {
    this.setState((prev) => ({
    isReactionDisplayed : !prev.isReactionDisplayed,
    currIndex : index,
    }))
    console.log("this is inside of onReactionClick",this.state.isReactionDisplayed)
  }

  // onReactionClickoutSide = () => {
  //   this.setState((prev) => ({
  //     isReactionDisplayed : !prev
  //   }))
  //   console.log("this is inside of onReactionClickoutSide",this.state.isReactionDisplayed)
  // }

  handleSelectEmoji = (index, emoji, message) => {
    this.props.socket.emit("chat reaction", JSON.parse(localStorage.getItem("room")), message, emoji);
    this.updateLocalStorage(message, emoji.native)
    this.onReactionClick(index)
    console.log("emoji form heandleSelectEmoji",emoji)
  }

  countUp = (message, emoji) => {
    console.log("emoji form coutUp",emoji)
    var isIncrement = this.updateLocalStorage(message, emoji)
    console.log("isIncrement",isIncrement)
    this.props.socket.emit("reaction countup", JSON.parse(localStorage.getItem("room")), message, emoji, isIncrement);
    console.log("message_id desu", message._id, message)
  }

  updateLocalStorage = (message, emoji) => {
    const localStorageReaction = JSON.parse(localStorage.getItem('reaction'));
    var isIncrement = true;

    if (localStorageReaction && localStorageReaction[message._id]) {
      Object.keys(localStorageReaction).forEach(function(key, index) {
        if (key === message._id) {
          console.log('localStorageReaction[message._id]', localStorageReaction[message._id])
          console.log(localStorageReaction[key], emoji)
          const newArr = localStorageReaction[key]
          if (newArr.includes(emoji)) {
            console.log("localStorageReaction[key]")
            // exists, decrement
            newArr.splice(newArr.indexOf(emoji), 1)
            isIncrement = false
          } else {
            // increment
            newArr.push(emoji)
            isIncrement =  true
          }
          localStorageReaction[key] = newArr
          localStorage.setItem("reaction", JSON.stringify(localStorageReaction));
        }
      })
    } else if (localStorageReaction && !localStorageReaction[message._id]){
      localStorageReaction[message._id] = [emoji]
      localStorage.setItem("reaction", JSON.stringify(localStorageReaction));
    } else {
      const storeEmoji = {[message._id]:[emoji]}
      localStorage.setItem("reaction", JSON.stringify(storeEmoji));
    }
    return isIncrement
  }

  handleEditClick = (index) => {
    this.setState({ editIndex: index });
  }

  handleCancelEdit = () => {
    // send an api request to update message in db
    const { messages, editIndex } = this.state;

    const updatedMessage= messages[editIndex];
    this.props.socket.emit("edit message", updatedMessage);
    this.setState({ editIndex: -1 });
  }

handleSearchTextChange = (event) => {
  this.setState({ searchText: event.target.value });
}

handleSearch = (event) => {
  event.preventDefault();
  const { messages, searchText } = this.state;
  const filteredMessages = messages.filter((message) => 
    message.message.toLowerCase().includes(searchText.toLowerCase())
  );
  this.setState({ filteredMessages });
}
  
handleSaveEdit = (index, editedText) => {
  // Update the message with the edited text
  const updatedMessages = [...this.state.messages];
  updatedMessages[index].message = editedText;
  this.props.socket.emit("edit message",updatedMessages[index]);

  this.setState({ messages: updatedMessages });
}

render() {
  const { messages, text, editIndex, searchText, filteredMessages } = this.state;
  const displayedMessages = filteredMessages || messages;
  
    return (
      <div>
        {/* Show chats */}
        {/* Show chat input box */}
        <ButtonGroup className="buttonGroup" variant="contained" aria-label="outlined primary button group">
          <Button onClick={this.logout}>Logout</Button>
          <Button onClick={this.handleBack}>Back</Button>
        </ButtonGroup>

        <form className="searchContainer" onSubmit={this.handleSearch}>
          <TextField className="searchInput" type="text" value={searchText} onChange={this.handleSearchTextChange} placeholder="Search messages"/>
          <Button className="searchButton" variant="contained" color="primary" type="submit">Search</Button>
        </form>

        <div className="chatContainer">
          <ul className="messageList">
            {displayedMessages.map((message, index) => (
              <li key={index} className="messageItem">
                <div>
                  {index === editIndex ? ( // If the message is being edited, show the input box
                  <div style={{display:'flex', justifyContent: 'space-between'}}>
                    <Avatar>ME</Avatar>
                    <TextField
                      type="text"
                      className="editTextInput"
                      value={message.message}
                      onChange={(e) =>
                        this.handleSaveEdit(index, e.target.value)
                      }
                    />
                    <Button className="saveButton" variant="contained" onClick={this.handleCancelEdit}>Save</Button>
                  </div>
                  ) : ( // Otherwise, show the message and edit button
                    <div style={{display:'flex', justifyContent: 'space-between'}}>
                      <Avatar>ME</Avatar>
                      <span style={{width:'50vw'}}>{message.message}</span>
                      <button variant="text" onClick={() => this.handleEditClick(index)}>
                        Edit
                      </button>
                    </div>
                  )}
                </div>
                <div>
                  <div className="reactions">
                    {message.emojis !== null && Object.entries(message.emojis).map(([key, value]) => (
                      <div key={key} className="emoji" onClick={() => this.countUp(message, key)}>
                        <div className="emo">{key}</div>
                        <div className="emo">{value}</div>
                      </div>
                    ))}
                    <div onClick={()=>this.onReactionClick(index)} style={{display:"flex", width:"3rem", padding:"0.5rem 1rem", justifyContent:"center",
                    borderRadius:"20px",backgroundColor:"rgb(231 231 231)", cursor:"pointer"}}>
                      <i 
                        className="fa fa-smile-o" 
                        style={{ fontSize: '1.5rem'}} 
                      />
                      <span>+</span>
                    </div>
                  </div>
                  {this.state.isReactionDisplayed && this.state.currIndex === index &&
                  <Picker
                    showPreview={false}
                    showSkinTones={false}
                    navPosition={"none"}
                    previewPosition={"none"}
                    theme={"light"}
                    onEmojiSelect={(emoji) => this.handleSelectEmoji(index, emoji, message)}
                    // onClickOutside={(e) => this.onReactionClick(index)}
                  />}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="bottomFixed">
          <TextField className="chatInput" type="text" id="text" value={text} onChange={this.handleTextChange} placeholder="Enter message" />
          <Button className="chatInputButton" variant="contained" color="primary" onClick={this.handleSendChat}>Send</Button>
        </div>
        {/* </div> */}
      </div>
    );
  }
}
export default Chatroom;

