import react from "react";
import './Lobby-Style.css';
import { TextField, Button } from "@mui/material";
import io from "socket.io-client";


class Lobby extends react.Component{
    constructor(props){
        super(props);
        this.state = {
            rooms: undefined,
            roomName: "",
            joinroomName:"",
            leaveroomName:"",
        }
        this.handleRoomNameChange = this.handleRoomNameChange.bind(this);

    }

    
      componentDidMount() {
        // TODO: write codes to fetch rooms that the user is in from the server
        const username =JSON.parse(localStorage.getItem("user")).username;
        console.log(JSON.parse(localStorage.getItem("user")));

        // console.log("username:"+username);
    //     const socket = io("http://localhost:3001",{
    //   cors:{
    //     origin:"http://localhost:3001",
    //     credentials:true,
    //     transports:['websocket']
    //   }
    // });
        // this.setState({ socket });
      
        fetch(this.props.server_url + "/api/rooms/all/"+username, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            this.setState({ rooms: data });
          })
          .catch((error) => {
            console.error("Error fetching rooms:", error);
          });
      }
      handleRoomNameChange = (event) => {
        this.setState({ roomName: event.target.value });
      };
      handleJoinRoomNameChange = (event) => {
        this.setState({ joinroomName: event.target.value });
      };
      handleLeaveRoomNameChange = (event) => {
        this.setState({ leaveroomName: event.target.value });
      };
      roomSelect = (room) => {
        const username = JSON.parse(localStorage.getItem("user")).username;
        // console.log("joining room", room);
        // this.props.socket.emit("join room", room ); // . move this to chatroom

        localStorage.setItem("room", JSON.stringify(room));
        this.props.changeScreen("chatroom");
      }
      
      createRoom = () => {
        const { roomName } = this.state;
        // TODO: Implement the logic to create a new room using the roomName
        // You can make an API request to your server here or update the state to reflect the new room
        console.log("Creating room:", roomName);
                const username =JSON.parse(localStorage.getItem("user")).username;
        console.log(username);
      
        fetch(this.props.server_url + "/api/rooms/create", {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            roomName:roomName,
          }),
        })
        .then((res) => res.json())
        .then((data) => {
            console.log(data);
            if(data.room){
this.setState((prevState) => ({ rooms: [...prevState.rooms, data.room] }));
            }
        })
        .catch((error) => {
          console.error("Error fetching rooms:", error);
        });
    

       

      };
      joinRoom = () => {
        const { joinroomName } = this.state;
        // TODO: Implement the logic to join the room using the roomName
        // You can make an API request to your server here or update the state to reflect the joined room
        console.log("Joining room:", joinroomName);
        const username = JSON.parse(localStorage.getItem("user")).username;
        console.log(username);
      
        fetch(this.props.server_url + "/api/rooms/join", {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            roomName: joinroomName,
          }),
        })
        .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              throw new Error("Failed to join the room");
            }
          })
          .then((data) => {
            console.log(data);
            
            this.setState((prevState) => ({
              rooms: [...prevState.rooms, data.room],
            }));
          })
          .catch((error) => {
            console.error("Error joining room:", error);
          });
      };
      leaveRoom = () => {
        const { leaveroomName } = this.state;
        // TODO: Implement the logic to join the room using the roomName
        // You can make an API request to your server here or update the state to reflect the joined room
        console.log("Leaving room:", leaveroomName);
        const username = JSON.parse(localStorage.getItem("user")).username;
        console.log(username);
        if (leaveroomName === "") {
          alert("Please enter a room name");
          return;
        }
        fetch(this.props.server_url + "/api/rooms/leave", {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            roomName: leaveroomName,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            this.setState((prevState) => ({
                rooms: prevState.rooms.filter((room) => room._id !== data.room._id),
              }));
          })
          .catch((error) => {
            console.error("Error joining room:", error);
          });
          
      };
      logout = () => {

        const username = JSON.parse(localStorage.getItem("user")).username;

        fetch(this.props.server_url+ "/api/auth/logout", {
            method: "POST",
            mode:"cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({username}),
        }).then((res) => {
            res.json().then((data) => {
                if (data.msg === "Logged out"){
                    this.props.changeScreen("auth");

                }
                else{
                    alert(data.msg)

                }
            });
        });
    };


    render() {

        return (
          <div className="container">
            <h1>Lobby</h1>
            <div style={{display:"flex", flexDirection:'column'}}>
              <div style={{display:"flex", justifyContent:'center', alignItems:'center', margin:"1rem auto", width:'60vw'}}>
                {this.state.rooms ? (
                  this.state.rooms.map((room) => (
                    <Button
                      className="buttonStyleRoom"
                      variant="contained"
                      key={"roomKey" + room._id} // Assuming room._id is a unique identifier
                      onClick={() => {   this.roomSelect(room)     }} // Assuming room.name is the room name
                    >
                      {room.name}
                    </Button>
                  ))
                ) : (
                  "loading..."
                )}
              </div>
        
              {/* write codes to join a new room using room id*/}
              {/* write codes to enable user to create a new room*/}
              <div className="formContainer">
                <TextField className="inputFields" type="text" onChange={this.handleRoomNameChange} placeholder="Enter room name" />
                <Button className="buttonStyle" variant="contained" onClick={this.createRoom}>
                  Create Room
                </Button>
              </div>

              <div className="formContainer">
                <TextField className="inputFields" type="text" onChange={this.handleJoinRoomNameChange} placeholder="Enter room name" />
                <Button className="buttonStyle" variant="contained" onClick={this.joinRoom}>
                  Join Room
                </Button>
              </div>

              <div className="formContainer">
                <TextField className="inputFields" type="text" onChange={this.handleLeaveRoomNameChange} placeholder="Enter room name" />
                <Button className="buttonStyle" variant="contained" onClick={this.leaveRoom}>
                  Leave Room
                </Button>
              </div>
              <div className="formContainer">
                <Button className="buttonStyle" variant="contained" onClick={this.logout}>
                  Logout
                </Button>
              </div>
            </div>
          </div>
        );
      }
}

export default Lobby;